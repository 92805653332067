<template>
	<div v-if="game">
		<div class="game" v-if="game">
			<div class="wrapper">
				<div class="game_row">
					<div class="game_image">
						<img :src="game.logo_image" alt="">
					</div>
					<div class="game_content">
						<div>
							<div class="game_title bold">{{ game.name }}</div>

							<div class="game_image __mobile">
								<img :src="game.logo_image" alt="">
							</div>

							<div class="game_breadcrumbs">
								<router-link v-if="game.platform_id === 1" to="/platform/android">Игры для скачивания</router-link>
								<router-link v-else-if="game.platform_id === 2" to="/platform/online">Онлайн игры</router-link>
								<span>/</span>
								<router-link v-if="game.platform_id === 1"
											 :to="'/platform/android/?genre=' + game.genre_id">
									{{ game.genre_name }}
								</router-link>
								<router-link v-if="game.platform_id === 2"
											 :to="'/platform/online/?genre=' + game.genre_id">
									{{ game.genre_name }}
								</router-link>
							</div>
						</div>

						<div class="game_buttons">
							<a v-if="game.api_url" :href="'/play/' + game.id" class="button __type-2" @click="openGame">Играть</a>
							<a v-else :href="game.path" class="button __type-2" @click="openGame">
								<span v-if="game.platform_id === 1">Скачать игру</span>
								<span v-else>Играть</span>
							</a>
							<a href="" class="game_favorite" :class="{__favorite: isGameFavorite}" @click.prevent="toggleFavorite"></a>
						</div>
					</div>
				</div>

				<div class="game_tags">
					<router-link :to="'/tag/' + tag.id" v-for="tag in game.tags">{{ tag.name }}</router-link>
				</div>

				<div class="game_logos">
					<div v-for="screenshot in game.screenshots" class="game_logo">
						<img :src="screenshot" alt="">
					</div>
				</div>

				<div class="game_row-main">
					<div class="game_description" v-html="game.description"></div>

					<div class="game_score" v-if="game.for_raiting">
						<div class="game_score-title">Лучшие игроки</div>

						<div class="game_score-table">
							<div class="game_score-tr" v-for="(score, num) in scores" :key="score.id">
								<div class="game_score-td">{{ num + 1 }}</div>
								<div class="game_score-td">
									<div class="game_score-main">
										<img :src="score.picture" alt="">
										<span>{{ score.name }}</span>
									</div>
								</div>
								<div class="game_score-td">{{ score.score }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mobile-view_popup" v-if="showPopup">
			Игра доступна по подписке MiХХ Play. Оформите подписку, чтобы получить доступ к этой и другим играм на любой вкус

			<br>
			<br>
			

			<div style="text-align: center;">
				<a href="https://msk.tele2.ru/mixx-play" class="button __type-2 __min">Оформить</a>
			</div>
			
			<div class="mobile-view_popup_close" @click="showPopup = false"></div>
		</div>
		<div class="mobile-view_popup" v-if="showMoneyPopup">
			Уважаемый абонент, для продолжения игры, пожалуйста, пополните ваш счет t2

			<br>
			<br>
			

			<div style="text-align: center;">
				<a href="https://msk.tele2.ru/payments/refill" class="button __type-2 __min">Пополнить</a>
			</div>
			
			<div class="mobile-view_popup_close" @click="showMoneyPopup = false"></div>
		</div>
	</div>
</template>

<script>
import YandexShare from '@cookieseater/vue-yandex-share';
import MainSlider from "@/components/MainSlider/MainSlider";

export default {
	components: {
		YandexShare,
		MainSlider,
	},
	data() {
		return {
			game: null,
			gameId: this.$route.params.id,
			scores: [],
			auth: window.auth,
			share: {
				title: '',
				description: 'Играй в любые игры без ограничений на портале Tele2'
			},
			showPopup: false,
			showMoneyPopup: false,
			favorites: null
		}
	},
	async created() {
		await this.fetchFavorites();
		
		this.axios.get('/games/' + this.gameId + '/').then(({data}) => {
			this.share.title = 'Давай сыграем вместе в ' + data.name;

			this.game = data;
		});

		this.axios.get('/games/' + this.gameId + '/scores/').then(({data}) => {
			if (data.data.length) {
				this.scores = data.data;
			}
		});
	},
	computed: {
		isGameFavorite() {
			if (!this.favorites) return false;
			
			return !!this.favorites.find(el => {
				return el.id == this.gameId
			})
		}
	},
	methods: {
		async fetchFavorites() {
			let {data} = await this.axios.get('/games/?fav=1');
			this.favorites = data.data;
		},
		openGame(e) {
			if (window.auth.subscribtion === 0 || window.auth.subscribtion === 3) {
				e.preventDefault();
				this.showPopup = true;
				return;
			} 
			else if (window.auth.subscribtion === 2) {
				e.preventDefault();
				this.showMoneyPopup = true;
				return;
			}
			if (this.game.platform_id !== 4 && this.game.platform_id !== 6) {
				if (!this.auth.msisdn) {
					e.preventDefault();

					let url = this.game.api_url ? '/play/' + this.game.id : '/game/' + this.game.id;

					localStorage.setItem('url', url);

					location.href = window.auth.login_url;
				} else if (!this.auth.subscribtion) {
					e.preventDefault();

					this.$router.push({
						name: 'subscribe'
					});
				}
			}
		},
		async toggleFavorite() {
			if (this.isGameFavorite) {
				await this.axios.delete('/games/' + this.gameId + '/fav');
			} else {
				await this.axios.post('/games/' + this.gameId + '/fav');
			}
			
			await this.fetchFavorites();
		}
	}
}
</script>

<style scoped>

</style>
