<template>
	<div>
		<div v-if="loading.all">
			<div class="game-block"
				 v-for="(category, key) in sortedCategories"
				 :key="'category' + category.id">
				<div v-if="!(isiOS() && category.name.search('Android') > -1)">
					<div class="wrapper">
						<div class="header-block">
							<h2 class="header-block_title bold">{{ category.name }}</h2>
						</div>

						<tab v-if="category.id !== 3" :headings="category.keys" :active-key="Object.keys(category.keys)[0]">
							<template :slot="gamesKey" v-for="(games, gamesKey) in category.games">
								<div class="game-block_row">
									<div class="game-block_col" v-for="(game, index) in games">
										<cart :game="game" :index="index"></cart>
									</div>
								</div>
							</template>
						</tab>
						
						<div class="game-block_row" v-else>
							<div class="game-block_col" v-for="(game, index) in popularGames">
								<cart :game="game" :index="index"></cart>
							</div>
						</div>

						<div style="margin-top: 10px; text-align: right;">
							<a v-if="category.id === 4" href="/platform/online" class="button __link">Все онлайн игры </a>
							<a v-if="category.id === 1" href="/platform/android" class="button __link">Все Android игры </a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="loading"></div>
	</div>
</template>

<script>
import Tab from "@/components/Tab/Tab";
import MainSlider from "@/components/MainSlider/MainSlider";
import MobileSlider from "@/components/MainSlider/MobileSlider";
import Cart from "@/components/Cart/Cart";

export default {
	components: {
		Tab,
		MainSlider,
		MobileSlider,
		Cart
	},

	data() {
		return {
			categoriesCount: 0,
			categories: {},
			popularGames: [],
			genres: [],
			loading: {
				all: false,
				_key: 0
			},
		}
	},

	computed: {
		sortedCategories() {
			if (!this.loading.all) return {};

			let categories = {};

			for (let cat in this.categories) {
				categories[this.categories[cat].key] = this.categories[cat];
			}

			return categories;
		}
	},

	async created() {
		this.axios.get('/games/genres/').then(({data}) => {
			this.genres = data.map(el => el.name);
		});
		
		let {data} = await this.axios.get('/games/categories/');
		
		this.categoriesCount = data.length;
		

		if (!this.categories)
			this.categories = {};
		
		for (let key in data) {
			let item = data[key];
			
			this.categories[item.id] = {
				id: item.id,
				name: item.name,
				games: null,
				key: key
			}

			await this.initGames(item.id);
		}
	},

	methods: {
		async initGames(id) {
			if (id === 3) {
				let url = '/games/?categories=' + id + '&limit=1000';
				
				
				if (this.isiOS()) {
					url += '&platforms=2';
				}
				let {data} = await this.axios.get(url);

				this.popularGames = data.data

				this.loading._key++;

				if (this.loading._key === this.categoriesCount) {
					this.loading.all = true;
				}
			} else {
				let url = '/games/?categories=' + id + '&limit=1000&rank=5';


				if (this.isiOS()) {
					url += '&platforms=2';
				}

				let {data} = await this.axios.get(url);

				this.categories[id].games = this.sortGames(data.data);

				this.genres = this.genres.filter(el => {
					return this.categories[id].games[el] && this.categories[id].games[el].length
				})

				this.categories[id].keys = this.setKeys(this.genres);

				this.loading._key++;

				if (this.loading._key === this.categoriesCount) {
					this.loading.all = true;
				}
			}
		},

		sortGames(games) {
			let gamesObj = {};

			games.forEach((game) => {
				if (!gamesObj[game.genre_name]) {
					gamesObj[game.genre_name] = [game];
				} else {
					gamesObj[game.genre_name].push(game)
				}
			});

			return gamesObj;
		},

		setKeys(keys) {
			let obj = {};

			for (let item in keys) {
				obj[keys[item]] = keys[item];
			}

			return obj;
		},
		isiOS() {
			return /iPad|iPhone|iPod/.test(navigator.userAgent);
		},
		isAndroid() {
			return /(android)/i.test(navigator.userAgent);
		}
	},
}
</script>

<style>
.header-block_title.__2 {
	color: #ff59a3;
}
.header-block_title.__3 {
	color: #a7fc00;
}
.header-block_title.__4 {
	color: #00bfff;
}
.header-block_title.__1 {
	color: #ff59a3;
}
</style>