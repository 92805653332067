<template>
	<div class="wrapper">
		<div>
			<div class="header-block">
				<h2 class="header-block_title bold">{{ title }}</h2>
			</div>

			<input type="text" class="search-input" placeholder="Поиск..." v-model="search" @input="fetch">
			
			<div v-if="loading.genres && loading.games">
				<div class="categories-nav" v-if="genres && (platformId == 1 || platformId == 2)">
					<router-link exact :to="{name: 'platform', params: { platform }}">Все</router-link>
					<router-link exact :to="{name: 'platform', params: { platform }, query: {genre: genre.id}}" v-for="genre in genres" :key="'genre' + genre.id">
						{{ genre.name }}
					</router-link>
				</div>
				
				<list :games="games"></list>
			</div>
			<div v-else class="loading"></div>
		</div>
	</div>
</template>

<script>
	import List from "@/components/List/List";
	
	export default {
		components: {
			List
		},
		data() {
			return {
				genres: null,
				genreId: this.$route.query.genre ? this.$route.query.genre : null,
				platform: this.$route.params.platform,
				platformId: null,
				title: '',
				games: null,
				loading: {
					games: false,
					genres: false
				},
				auth: window.auth,
				search: ''
			}
		},
		
		created() {
			this.setPlatform();
			
			this.fetch();
		},
		
		methods: {
			fetch() {
				this.axios.get('/games/genres/?platform=' + this.platformId).then(({data}) => {
					this.genres = data;
					this.loading.genres = true;
				});

				if (this.genreId) {
					this.axios.get('/games/?name=' + this.search + '&limit=10000&genres=' + this.genreId + '&platforms=' + this.platformId).then(({data}) => {
						this.games = data.data

						this.loading.games = true;
					});
				} else {
					this.axios.get('/games/?name=' + this.search + '&limit=10000&platforms=' + this.platformId).then(({data}) => {
						this.games = data.data
						this.loading.games = true;
					});
				}
			},
			
			setPlatform() {
				if (this.platform === 'android') {
					this.title = 'Игры для скачивания';
					this.platformId = 1;
				} else if (this.platform === 'online') {
					this.title = 'Онлайн игры';
					this.platformId = 2;
				} else if (this.platform === 'free') {
					this.title = 'Бесплатные игры';
					this.platformId = 4;
				}
                else if (this.platform === 'mixx') {
                    this.title = 'Mixx';
                    this.platformId = 6;
                }
			},
			isAndroid() {
				return /(android)/i.test(navigator.userAgent);
			}
		},
	}
</script>

<style>
.search-input {
	width: 100%;
	margin-bottom: 20px;
	padding: 10px;
	border: 1px solid #eee;
	text-align: left;
}
</style>
