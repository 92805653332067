<template>
	<div class="games-block __pt0">
		<div class="games-block_row" v-if="games">
			<div class="games-block_col" v-for="game in gamesPaged" :key="'game' + game.id">
				<div @click="goTo(game, $event)" class="games-block_item">
					<div class="games-block_image">
						<img :src="game.logo_image" alt="">
					</div>
					<div class="games-block_content">
						<div class="games-block_name">{{ game.name }}</div>
						<div class="games-block_cat" :class="['__' + game.genre_id]">{{ game.genre_name }}</div>
						<div class="games-block_text" v-html="game.description"></div>

						<a href="" v-if="favorites" class="games-block_fav" :class="{__favorite: isGameFavorite(game)}" @click.prevent="removeFromFavorite(game)"></a>
						
						<div class="games-block_button">
							<router-link :to="{name: 'game', params: {id: game.id}}" class="button __type-2 __min">Играть</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="games-block_page-button" v-if="gamesPaged.length < games.length && isPaged">
			<a href="" class="button __type-2" @click.prevent="changePage">Загрузить еще</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			games: Array,
			isPaged: {
				type: Boolean,
				default: true
			},
			favorites: {
				type: Array,
				required: false
			}
		},
		
		data() {
			return {
				page: 1,
			}
		},

		computed: {
			gamesPaged() {
				if (!this.isPaged) {
					return this.games;
				}
				
				let total = 9;

				return this.games.slice(0, total * this.page);
			},
		},
		
		methods: {
			changePage() {
				this.page++;
			},
			isGameFavorite(game) {
				if (!this.favorites) return false;

				return !!this.favorites.find(el => {
					return el.id == game.id
				})
			},
			removeFromFavorite(game) {
				this.$emit('removeFavorite', game)
			},
			goTo(game, e) {
				if ($(e.target).closest('.games-block_fav').length) {
					return;
				}
				this.$router.push({
					name: 'game',
					params: {
						id: game.id
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>
