<template>
	<div id="full-screen">
		<div id="append"></div>

		<div id="full-screen_click" class="full-screen_click" :class="{__opened: opened}" @click="openFull">

		</div>
	</div>
</template>

<script>
var globalIframe, globalUrl;

export default {
	data() {
		return {
			id: this.$route.params.id,
			game: null,
			auth: window.auth,
			iframe: null,
			resized: false,
			opened: false
		}
	},

	created() {
		if (!window.auth.subscribtion || [0, 2, 3].includes(window.auth.subscribtion)) {
			return;
		}

		this.axios.get('/games/' + this.id + '/').then(({data}) => {
			this.game = data;

			this.initIframe(this.game);
		});
	},
	mounted() {
		$(window).on('orientationchange webkitfullscreenchange mozfullscreenchange fullscreenchange', () => {
			if (this.resized) {
				return;
			}
			this.resized = true;

			$(document).find('embed').remove();

			this.initIframe(this.game);

			this.resized = false;

			//this.opened = false;
		});
	},

	methods: {
		initIframe(game) {
			this.iframe = document.createElement('embed');
			this.iframe.id = 'game-frame';
			this.iframe.className = 'game-frame';
			this.iframe.src = game.api_url;
			this.iframe.setAttribute('frameborder', '0');
			this.iframe.setAttribute('name', window.location.href);

			var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
			var eventer = window[eventMethod];
			var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
			eventer(messageEvent, (e) => {
				switch (e.data.type) {
					case 'send':
						this.sendScore({
							type: e.data.label,
							level: e.data.level,
							score: e.data.score
						});
						break;
				}
			}, false);
			document.getElementById('append').appendChild(this.iframe);
			globalIframe = this.iframe;
		},
		playGame(url) {
			globalUrl = url;
			globalIframe.contentWindow.postMessage({
				message: 'callbackExecuted'
			}, url);
		},
		sendScore(obj) {
			if (this.game.for_raiting) {
				this.axios.post('/games/' + this.id + '/scores/', {score: obj.score});
			}
		},
		openFull() {
			this.opened = true;

			this.openFullscreen(document.getElementById('full-screen'))
		},
		openFullscreen(elem) {
			//# for most browsers
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			}

			//# for Safari (older versions)
			else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen();
			}
		
			//# for Safari (newer versions)
			else if (elem.webkitEnterFullscreen) {
				elem.webkitEnterFullscreen();
			}

			//# for Safari iPhone (where only the Video tag itself can be fullscreen)
			else if (elem.children[0].webkitEnterFullscreen) {
				elem.children[0].webkitEnterFullscreen();
				//toggle_controls(); //# your own function to show/hide iOS media controls
			}

			//# for Internet Explorer 11
			else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen();
			}
		}
	}
}
</script>

<style>
#full-screen {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 9999999;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	cursor: pointer;
}

.full-screen_click {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999999;
}

.full-screen_click.__opened {
	pointer-events: none;
	display: none;
}

@supports (-webkit-touch-callout: none) {
	.full-screen_click {
		pointer-events: none;
		display: none;
	}
}
</style>
