<template>
	<div v-if="showHeader">
		<header class="header">
			<div class="wrapper">
				<div class="header_row">
					<router-link :to="{name: 'home'}" class="header_name">
						<img src="/img/logo.svg">
					</router-link>

					<div v-if="auth.authGot">
						<a href="" v-if="!auth.msisdn" @click.prevent="authUser" class="header_btn-auth">Войти</a>
						<div v-else class="header_top">
							<a href="" class="header_btn-user" @click.prevent="showAuthForm = !showAuthForm"></a>

							<div class="header_links" v-if="showAuthForm">
								<div @click="showAuthForm = false">
									<router-link to="/profile">Личный кабинет</router-link>
								</div>
								<a href="" @click.prevent="logout">Выйти</a>
							</div>
						</div>
					</div>

					<div class="header_ico" @click="showMenu = true"><i></i><i></i><i></i></div>
				</div>
			</div>
		</header>

		<div class="menu">
			<div class="wrapper">
				<div class="menu_list">
					<router-link class="only-iphone" v-if="!isiOS()" to="/platform/android">Игры для скачивания</router-link>
					<router-link to="/platform/online">Онлайн игры</router-link>
					<router-link to="/favorites" v-if="auth.authGot">Избранное</router-link>
					<router-link to="/mobile">Мобильное приложение</router-link>
				</div>
			</div>
		</div>

		<div class="header_menu" v-if="showMenu">
			<div class="header_menu-top">
				<div class="header_menu-name"></div>
				<div class="header_menu-close" @click="showMenu = false"></div>
			</div>

			<div class="header_menu-data">
				<ul>
					<li class="header_menu-item" v-if="!isiOS()">
						<router-link exact class="a" to="/platform/android" @click.native="showMenu = false">
							<span @click="showMenu = false">Игры для скачивания</span>
						</router-link>
					</li>
					<li class="header_menu-item">
						<router-link exact class="a" to="/platform/online" @click.native="showMenu = false">
							<span @click="showMenu = false">Онлайн игры</span>
						</router-link>
					</li>
					<li class="header_menu-item" v-if="auth.authGot">
						<router-link exact class="a" to="/favorites" @click.native="showMenu = false">
							<span @click="showMenu = false">Избранное</span>
						</router-link>
					</li>
					<li class="header_menu-item">
						<a v-if="!isiOS()" href="" class="a" @click.prevent="download" @click="showMenu = false">
							<span>Мобильное приложение</span>
						</a>
						<router-link to="/mobile-ios" class="a" v-else @click.native="showMenu = false">
							<span>Установка иконки сервиса</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		
		
		<div class="info" v-if="canSee()">
			<div class="info_content">
				<p>Добавьте ярлык <strong>MiXX Play</strong> на экран «Домой»</p>
				Нажмите на иконку <img width="20" src="/img/share.png" alt=""> и выберите «На экран Домой»
			</div>
			<a href=""  class="info_close" @click.prevent="disableInfo">
				<img src="/img/close.svg" alt="">
			</a>
		</div>
	</div>
</template>

<script>
import _ from 'underscore';
import $ from 'jquery';

export default {
	data() {
		return {
			auth: window.auth,
			showAuthForm: false,
			showMenu: false,
			showIosForm: false,
			visibleInfo: true
		}
	},


	created() {
		
	},

	mounted() {

	},

	computed: {
		showHeader() {
			return location.pathname !== '/platform/mixx' && this.$route.name !== 'play';
		}
	},

	methods: {
		authUser() {
			localStorage.setItem('url', window.location.pathname);

			location.href = window.auth.login_url;
		},
		isiOS() {
			return /iPad|iPhone|iPod/.test(navigator.userAgent);
		},
		logout() {
			this.axios.get('/games/t2_logout').then(() => {
				location.href = 'http://sso.t2.ru/auth/realms/tele2-b2c/protocol/openid-connect/logout?redirect_uri=https://mixxplay.ru';
			})
		},
		checkUnsubscribe() {
			axios.get('/games/profile').then(({data}) => {
				if (!data.subscription) {
					location.reload();
				} else {
					setTimeout(() => {
						this.checkUnsubscribe();
					}, 500);
				}
			})
		},
		download() {
			location.href = '/mixxplay.apk'
		},
		canSee() {
			let disabledInfo = localStorage.getItem('disabled_info');
			
			return !(this.isStandAlone() || disabledInfo || !this.visibleInfo || !this.isiOS());
		},
		isStandAlone() {
			return ("standalone" in window.navigator) && window.navigator.standalone
		},
		disableInfo() {
			localStorage.setItem('disabled_info', 'true')
			
			this.visibleInfo = false;
		}
	}
}
</script>
