<template>
	<div class="wrapper">
		<div>
			<div class="header-block">
				<h2 class="header-block_title bold">Избранное</h2>
			</div>
			
			<div v-if="games">
				<list :games="games" :is-paged="false" :favorites="games" @removeFavorite="removeFavorite"></list>
			</div>
			<div v-else class="loading"></div>
		</div>
	</div>
</template>

<script>
import List from "@/components/List/List";

export default {
	components: {
		List
	},
	data() {
		return {
			games: null,
			loading: true,
			auth: window.auth,
		}
	},

	created() {
		this.fetch();
	},

	methods: {
		fetch() {
			this.axios.get('/games/?fav=1').then(({data}) => {
				this.games = data.data;
				this.loading = false;
			});
		},
		async removeFavorite(game) {
			await this.axios.delete('/games/' + game.id + '/fav');
			
			await this.fetch();
		}
	},
}
</script>

<style>
</style>
